

.event-settings-card .date-container {

}

.event-settings-card .date-container.dirty-date .react-datetime-picker {
    background-color: rgb(221 228 239);
}
.event-settings-card .date-container.invalid-date .react-datetime-picker {
    background-color: #ff000033;
}

.event-settings-card .date-container.error-date .react-datetime-picker {
    border-style: solid;
    border-color: red;
    border-width: 5px;
}

.event-settings-card .react-datetime-picker {
    margin-top: 5px;
}

.event-settings-card .react-datetime-picker__wrapper {
    border: #ced4da;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.25rem;
    padding: 5px;    
}

.event-settings-card .esc-oculusbanner .dcc-title,
.event-settings-card .esc-oculusnotes .dcc-title {
    margin-bottom: 5px;
}

.event-settings-card .esc-oculusbanner,
.event-settings-card .esc-oculusnotes {
    margin-bottom: 12px;
}

.infiniverse-card .ic-action-cell {
    width: 113px
}

.infiniverse-card .ic-action-cell button {
    margin: 2px;
}