.basic-page {}

.basic-page .bp-title-header {
    display: inline-block;
    margin-bottom: .5rem;
}

.basic-page .bp-title-button {
    display: inline-block;
    vertical-align: top;
    padding-left: 5px;
}