.dhp-upgrade-button {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 5px;
    border-radius: 10px;
    display: inline-block;
}

.dhp-plan-box {

}