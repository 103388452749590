.image-dropper{
    transition: opacity .3s ease-in-out;
}

.image-dropper>.file-drop-target{
    position: relative;
}

.image-dropper .add-dropdown {
    position: absolute;
    top: 50%
}

.image-dropper .options-dropdown {
    position: absolute;
    top: 10px;
    left: 10px;
}

.image-dropper .togglebutton {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    padding: 0px;
    background-color: lightgrey;
    box-shadow: 0px 0px 2px 2px #aaaaaa;
    border-color: transparent;
}

.image-dropper .togglebutton:after {
    content: none;
}

.image-dropper .options-dropdown .togglebutton {
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.image-dropper .img-thumbnail.empty-state{
    height: 285px;
    width: 100%;
}
.image-dropper .img-thumbnail.empty-state span{
   position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
}

.image-dropper.file-drop-disabled{
    opacity: 0.3;
}