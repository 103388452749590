.approval-page .aap-location, .approval-page .aap-advert {
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f8f9fa;
}

.approval-page .aap-location button {
    width: 100px;
    margin-right: 2px;
}

.approval-page .aap-variable img {
    max-width: 100px;
    max-height: 100px;
    display: block
}
.approval-page .aap-advert img {
    height: 150px;
    aspect-ratio: 1920/1080;
    object-fit: cover;
}