/*Styling of the aspect ratio box (main css properties are in global file*/

.door-selector {
    margin-bottom: 0.5rem;
}

.door-selector p {
    margin: 0
}

.door-selector .aspect-ratio-sizer {
    border-color: #6baae8;
    border-width: 3px;
    border-style: solid;
}

.door-selector .form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    padding: 30px;
}

.door-selector .save-button {
    margin-left: 3px;
    display: inline-block;
    width: 68px
}

.door-selector .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.door-selector-hidespinner .spinner-border {
    display: none;
}

.door-selector-showspinner .form {
    display: none;
}