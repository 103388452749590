.domain-members-page .header-button {
    width: 40px;
    height: 40px;
    background-color: #efefef;
}

.domain-members-page .header-button .icon-image {
    width: 35px;
    height: 35px;
    border-radius: 0%;
    transform: translate(2px, -2px);
}

.domain-members-page .member-box {
    height: 36px
}