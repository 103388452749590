/*Defaults taken from https://sarink.github.io/react-file-drop/ as a starting point*/

.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100px;
    width: 100%;
}

.file-drop>.file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    text-align: center;
}

.file-drop>.file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    /*border: none;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    z-index: 50;
    opacity: 1;*/
    /* typography */
    /*color: white;*/
}

.file-drop>.file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    /*color: #ff6e40;
    box-shadow: 0 0 13px 3px #ff6e40;*/
}

.file-drop {
    width: 100%;
    height: 100%
}

.file-drop input[type="file"] {
    display: none
}

.file-drop>.file-drop-target {
    border: none;
    background-color: #fbfbfb;
}

.file-drop>.file-drop-target.file-drop-dragging-over-target {
    background-color: #f0f0f0;
}

.content-selector {
    margin-bottom: 0.5rem;
}

.content-selector p {
    margin: 0
}


/*Styling of the aspect ratio box (main css properties are in global file*/

.content-selector .aspect-ratio-sizer {
    border-color: #6baae8;
    border-width: 3px;
    border-style: solid;
}


/*Basic drop down positioning*/

.content-selector .add-dropdown {
    position: absolute;
    top: 50%
}

.content-selector .options-dropdown {
    position: absolute;
    top: 5px;
    left: 5px;
}


/*Drop down button styling*/

.content-selector .togglebutton {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    padding: 0px;
    background-color: lightgrey;
    box-shadow: 0px 0px 2px 2px #aaaaaa;
    border-color: transparent;
}

.content-selector .togglebutton:after {
    content: none;
}

.content-selector .options-dropdown .togglebutton {
    height: 30px;
    width: 30px;
    border-radius: 15px;
}


/*Description above the add button*/

.content-selector .description {
    position: absolute;
    bottom: 50%;
    padding: 5px;
    transform: translateY(-5px);
}


/*Transitions for general content boxes*/

.content-selector .content {
    transition: all 0.2s ease-in-out;
}


/*Additional content styles for video js

.content-selector .video-js {
    width: 100%;
    height: 100%;
    background-color: white;
}*/

/* progress bar */
.content-selector .progress-bar-wrapper {
    width: 90%
}
.content-selector .progress-bar-wrapper .progress {
    height: 35px
}

/*Warning mark style*/

.content-selector .warning {
    position: absolute;
    top: 5px;
    right: 5px;
}


/*For hover supporting device allow add button and description to show when 
hovering over box showing content, and make the content go nearly transparent

@media (hover: hover) {
    .content-selector-hover .content {
        opacity: 0.1
    }
    .content-selector-hover .add-dropdown,
    .content-selector-hover .description {
        display: unset;
    }
}
*/

/*Similar opacity rule for when dropping image into box*/

.file-drop>.file-drop-target.file-drop-dragging-over-target img,
.file-drop>.file-drop-target.file-drop-dragging-over-target video {
    opacity: 0.1
}


/*Helper styles to show/hide base don whether hovering is supported on device*/

@media (hover: none) {
    .content-selector .hover-hover {
        display: none;
    }
}

@media (hover: hover) {
    .content-selector .hover-none {
        display: none;
    }
}

/*Basic hide rules*/

.content-selector-hidespinner .spinner-border {
    display: none;
}

.content-selector-hideprogress .progress-bar-wrapper {
    display: none;
}


.content-selector-hidecontent .content {
    display: none;
}

.content-selector-hideinfo .add-dropdown,
.content-selector-hideinfo .description {
    display: none;
}

.content-selector-hidewarning .warning {
    display: none;
}

.content-selector-hideoptions .options-dropdown {
    display: none
}


/*hide conent, add button and description if spinning*/

.content-selector-showspinner .content,
.content-selector-showspinner .add-dropdown,
.content-selector-showspinner .description {
    display: none;
}

.content-selector-showprogress .content,
.content-selector-showprogress .add-dropdown,
.content-selector-showprogress .description {
    display: none;
}

/*For none hover supporting device with content, never show info*/

@media (hover: none) {
    .content-selector-showcontent .add-dropdown,
    .content-selector-showcontent .description {
        display: none;
    }
}