
.entity-mini-info-box img {
    width: 40px;
    margin-right: 5px;
}

.entity-mini-info-box .col-auto,
.entity-mini-info-box .col {
    padding: 0px;
}

.entity-mini-info-box span {
    font-size: 30px;
    vertical-align: middle;
}

