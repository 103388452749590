.browse-drive-item-dialog .video-js {
    width:100%
}

.browse-drive-page {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


