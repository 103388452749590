
.browse-drive-dialog {

}

.browse-drive-dialog .modal-dialog {
    max-width: unset;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 15px
}

.browse-drive-dialog .modal-content {
    height: 100%;
}

.browse-drive-dialog .modal-body {
    padding: 0px;
    padding-bottom: 3px;
}

.browse-drive-dialog .drive-browser-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: none;
    padding-left: 12px;
    padding-right: 20px;
}

.browse-drive-dialog .drive-browser-grid .dbg-grid {
    grid-template-columns: repeat(auto-fill, minmax(144px,1fr));   
}