.edit-item {
    max-width: 768px;
}

.image {
    flex-grow: 1;
    border-color: #6baae8;
    border-width: 3px;
    border-style: solid;
}

.icon {
    max-width: 256px;
}
