.adverts-page .dp-icon-cell {
    min-width: 100px;
}

.adverts-page button.purchase-impressions {
    display: block;
    font-size: 75%;
    margin-top: -0.3em;
}

.center.container-fluid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.adverts-page .page-table .icon-cell img {
    max-width: unset;
    object-fit: cover;
    height: 75px;
    width: 134px;
}

.adverts-page .page-table td{
    line-height: 1.1;
}

.adverts-page tr {
    transition: opacity 0.2s 0s;
    opacity: 1;
}
.adverts-page tr.loading{
    transition: opacity 0.8s 0.3s;
    opacity: 0.3;
}
