.template-select-grid {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.template-select-item {
    background-color: white;
    position: relative;
}

.template-select-item .tsi-content {
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-width: 1px;
}

.template-select-item .tsi-image {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
}

.template-select-item .tsi-image img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.template-select-item .tsi-text {
    font-size: 12px;
    height: 40px;
    padding: 4px;
    font-weight: bold;
}

.template-select-item.tsi-selected .tsi-content {
    background-color: #a2cefd;
    border-color: #a2cefd;
}

.template-select-item.tsi-disabled .tsi-content {
    background-color: lightgray;
    border-color: lightgray;
}