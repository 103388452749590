.browse-drive-icon p {
    font-size: 0.8rem;
    word-wrap: break-word;
}

.browse-drive-icon button {
    overflow: clip;
}

.browse-drive-icon img,
.browse-drive-icon svg {
    width: 90%;
    height: 70%;
    object-fit: contain;
}