
.background-task-runner {
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: right;
    border-radius: 3px;
    white-space: nowrap;
    background-color: white;
    padding: 5px;
    border-color: #6baae8;
    border-style: solid;
    z-index: 1100;
    max-width: 250px;
    opacity: 0.9;
}
 .background-task-runner .task {
     padding: 2px;
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
 }

.background-task-runner .active {
    font-weight: bold;
}

.background-task-runner-idle {
    display: none;
}