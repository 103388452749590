.restapi .btn-link:focus {
    border: none;
    box-shadow: none;
}

.restapi-fields-col {
    padding-right: 0px;
    max-width: 166px;
}

.restapi-fields-col table {
    table-layout: fixed;
    font-size: 10px;
}

.restapi-fields-col th,
.restapi-fields-col td,
.restapi-fields-col tr {
    padding: 0px;
    height: unset;
    overflow-x: clip;
    text-overflow: ellipsis;
}

.restapi-fields-col th:nth-child(2),
.restapi-fields-col td:nth-child(2),
.restapi-fields-col th:nth-child(3),
.restapi-fields-col td:nth-child(3) {
    width: 20px;
    text-overflow: clip
}

.restapi-documents-col table {
    table-layout: fixed;
    font-size: 12px;
}

.restapi-documents-col th,
.restapi-documents-col td,
.restapi-documents-col tr,
.restapi-documents-col button {
    padding: 2px;
    height: unset;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.restapi-documents-col th:nth-child(1),
.restapi-documents-col td:nth-child(1) {
    width: 22px
}

.restapi-documents-col td:nth-child(1) button {
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

.restapi-search-row {
    padding-bottom: 7px;
}

.restapi-search-row>*:nth-child(1) {
    max-width: 70px;
    padding-top: 4px;
}

.restapi-search-row textarea,
.restapi-search-row input {
    width: 100%;
    font-family: 'Courier New', Courier, monospace;
}

.restapi-search-row>*:nth-child(2) {
    padding-right: 5px;
}

.restapi-search-row>*:nth-child(3) {
    max-width: 60px;
    padding-left: 0px;
}

.restapi-search-row button,
.restapi-search-row input {
    width: 100%
}

.restapi-search-row button {
    padding-left: 0;
    padding-right: 0;
    padding-top: 2px;
    padding-bottom: 2px;
}

.restapi-documentfields-col table {
    table-layout: fixed;
    font-size: 12px;
}

.restapi-documentfields-col th,
.restapi-documentfields-col td,
.restapi-documentfields-col tr,
.restapi-documentfields-col button {
    padding: 2px;
    height: unset;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.restapi-document>.row {
    padding-bottom: 8px;
}

.restapi-entityicon img {
    width: 60px;
    height: 60px;
}

.restapi-entityicon {
    max-width: 60px;
}

.restapi-document-operations .row {
    padding-top: 8px;
    padding-bottom: 8px;
}

.restapi-devmessage select {
    /*color: red;*/
    font-weight: bold;
}

.restapi-code-editor * {
    font-family: 'Courier New', Courier, monospace;
}
.restapi-code-editor pre {
    min-height: 200px;
}

.restapi-custom-styles .container-fluid {
    padding: 5px
}

.restapi-custom-styles button {
    width: 130px;
    margin-right: 5px;
    margin-bottom: 5px
}
