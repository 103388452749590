{

}

.dcc-content-box {
    display: flex;
    flex-direction: column;
}
.dcc-content-box>*:nth-child(1) {
    flex-grow: 0;
}
.dcc-content-box>*:nth-child(2) {
    flex-grow: 1;
    border-color: #6baae8;
    border-width: 3px;
    border-style: solid;
}

.dcc-description-box {
    position: relative;
    height: 100%
}

.dcc-description-box textarea {
    position: relative;
    height: 100%;
    resize: none;
}

.spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.dcc-image-box-description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center; 
}

.dcc-title {

}

.dcc-content-box .options-dropdown {
    position: absolute;
    top: 5px;
    left: 5px;
}
.dcc-content-box .options-dropdown .togglebutton {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    padding: 0px;
    background-color: lightgrey;
    box-shadow: 0px 0px 2px 2px #aaaaaa;
    border-color: transparent;
}

.dcc-content-box .options-dropdown .togglebutton:after {
    content: none;
}

.dcc-error>:nth-child(2) {
    border-style: solid;
    border-color: red;
    border-width: 5px;
}

.col-25-16 {
    flex-basis: 100%;       
}
.col-25-9 {
    flex-basis: 100%;       
}
@media (min-width: 576px) {
    .col-25-16 {
        flex-basis: 64%;               
    }
    .col-25-9 {
        flex-basis: 36%;                      
    }
}