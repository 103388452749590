
.mv-circular-button {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 50%;
    border: none;
    box-shadow: none !important;
}

.mv-circular-button svg {
    width: 75%;
    height: 75%
}

.mv-circular-button.dropdown-toggle:after {
    display: none;
}

.mv-circular-button:disabled svg {
    opacity: 0.5;
}