.contact-us-dialog .form-label {
    margin-bottom: 1px;
}

.contact-us-dialog .modal-footer {
    padding-top: 5px;
    padding-bottom: 5px;
}

.contact-us-dialog textarea {
    padding-bottom: 0px;
}