

.hc {

}

.hc-body {
    position: relative;
    padding-top: 15px;
    text-align: center;
    pointer-events: none;
}
.hc-body h3 {
    display: inline-block;
    margin-top: 9px;
    margin-bottom: 8px;
    max-width: 100%;
}
.hc-body * {
    pointer-events: all;
}

.hc-iconbody {
    position: relative;
    padding: 0px;
    justify-content: center;
    display: flex;
}

.hc-iconholder {
    height: 100px;
    width: 100px;
    padding: 0px;
    position: absolute;
    transform: translate(0,-80%);
    border-width: 3px;
    border-color: white;
    border-style: solid;
    border-radius: 50%;
    background-color: white;
}


.hc-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.hc-icon-error .hc-icon {
    border-style: solid;
    border-color: red;
    border-width: 5px;
}

.hc-bannerbody {
    padding: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
}

.hc-bannerplaceholder {
    padding-bottom: 25%;
}

.hc-banner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.hc-banner-error .hc-banner {
    border-style: solid;
    border-color: red;
    border-width: 5px;  
}

.hc-circlebutton {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 0px;
}

.hc-circlebutton :first-child {
    color: #212529
}

.hc-circlebutton svg:first-child {
    position: relative;
    transform: translate(0.5px,-1.5px);
    color: #212529
}

.hc-circlebutton .spinner-border {
    width: 16px;
    height: 16px;
}

.hc-circlebutton,
.hc-circlebutton:focus,
.hc-circlebutton:active,
.hc-circlebutton:disabled {
    background-color: lightgray;
    border:none;
    box-shadow: none;
}

.hc-camerabutton {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.hc-bannerbody .hc-camerabutton {
    bottom: 5px;
    right: 5px;
}


.hc-pencilbutton {
    position: relative;
    transform: translate(5px,-5px);
}

.hc-fileinput {
    display: none
}

.hc-titlebox {
    font-size: x-large;
    text-align: center;
}

.hc-title-error .hc-body h3 {
    border-style: solid;
    border-color: red;
    border-width: 5px;  
}

.hc-devmessage {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 4px;
    background-color: #f8f9faab;
    z-index: 1;
}

.hc-error {
    background-color:mistyrose;
}