.mv-content-box {
    width: 100%;
    height: 100%;
}

.mv-content-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mv-content-box iframe {
    width: 100%;
    height: 100%;
}

.mv-content-box .data-vjs-player,
.mv-content-box .video-js {
    width: 100%;
    height: 100%;
}

.mv-content-box .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: none;
    position: absolute
}

.mv-content-box .mv-content-box-warning {
    top: 5px;
    right: 5px;
    position: absolute;
    color: #dc3545;
}