.purchase-domain {}

.purchase-domain .pd-title-col {
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 5px;
}

.purchase-domain .pd-plan-col {
    margin-right: 5px;
}

.purchase-domain .pd-plan-col:last-child {
    margin-right: 0px;
}

.purchase-domain .pd-plan-disabled,
.purchase-domain .pd-plan-default {
    background-color: rgb(248 249 250);
}

.purchase-domain .pd-plan-suggested {
    background-color: #dfeff9;
}

.purchase-domain .pd-title-col {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.purchase-domain .pd-footer-col {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.purchase-domain .pd-subtitle-col {
    font-size: 0.9em;
    padding-bottom: 10px;
}

.purchase-domain .pd-price-col {
    font-size: 1.5em;
    padding-bottom: 15px;
    text-decoration-style: dotted;
}

.purchase-domain .pd-price-col * {
    text-decoration-style: dotted;
}

.purchase-domain .pd-button-col {
    padding-bottom: 30px;
}

.purchase-domain .pd-button-col button {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px
}

.purchase-domain .pd-descriptionheader-col {
    font-size: 0.9em;
    font-weight: bold;
    padding-bottom: 5px;
}

.purchase-domain .pd-description-col {
    font-size: 0.9em
}

.purchase-domain .pd-description-tick {
    padding: 0px;
}

.purchase-domain .pd-description-tick svg {
    width: 20px;
    height: 20px;
    color: green;
}

.purchase-domain .pd-description-text {
    padding-left: 5px;
}

.purchase-domain .pd-footer-col {
    padding-bottom: 15px;
    margin-bottom: 5px;
}

.purchase-domain .pd-loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffffd4;
    display: none;
}

.purchase-domain.pd-busy .pd-loading-overlay {
    display: unset;
}

.pd-needowner-dialog a {
    color: #007bff;
}

.purchase-domain .pd-contactus {
    padding: 0;
    margin: 0;
    transform: translate(2px,-2px)    
}