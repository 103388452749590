/*-----------------------------------------------------------------------------------
Style overrides to make one of the circular buttons transparent
-----------------------------------------------------------------------------------*/

.drive-browser-transparent-button {
    background-color: transparent;
    border-style: none;
}

.drive-browser-transparent-button:disabled,
.drive-browser-transparent-button:active,
.drive-browser-transparent-button:hover {
    background-color: transparent;
}

.drive-browser-transparent-button:focus {
    box-shadow: none !important;
    background-color: transparent;
}

/*-----------------------------------------------------------------------------------
Drive Browser Header
-----------------------------------------------------------------------------------*/

.drive-browser-header {
    white-space: nowrap;
    background-color: #e9ecef;
    border-top: darkgrey;
    border-top-style: solid;
    border-top-width: 1px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 30px;
    position: relative;
}

.drive-browser-header .dbh-name-button {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: clip;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 14px;
    transform: translateY(6px);
    font-weight: bold;
    color: #525252;
}

.drive-browser-header .dbh-path-separator {
    width: 14px;
    height: 14px;
    transform: translateY(5px);
}

.drive-browser-header .dbh-up-button {
    padding: 0px;
    margin: 0px;
    padding-top: 2px;
    color: #525252;
}

.drive-browser-header .dbh-up-button svg {
    width: 30px;
    height: 30px
}

.drive-browser-header .row {
    flex-wrap: nowrap;
    padding: 0px;
}

.drive-browser-header .col,
.drive-browser-header .col-auto {
    padding: 0px;
    overflow-x: clip;
}

.drive-browser-header .dbh-tool-buttons {
    text-align: right;
    overflow-x: visible;
}
.drive-browser-header .dropdown {
    text-align: left;
}

.drive-browser-header .dbh-tool-buttons button {
    margin-left: 8px;
}

.drive-browser-header .dbh-toolbar-separator {
    display: inline-block;
    background-color: darkgrey;
    width: 1px;
    height: 40px;
    padding: 0px;
    margin: 0px;
    margin-left: 5px;
    vertical-align: middle;
}

.dbh-storage {
    text-align: right;
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    height: 40px;
    overflow: hidden;
    width: 290px;
    padding-top: 5px;
    white-space: nowrap;    
    max-width: 100%;
}
.dbh-storage .progress {
    height: 8px
}
.dbh-storage-full .progress-bar {
    background-color: #dc3545;
}

/*-----------------------------------------------------------------------------------
Drive Browser Item
-----------------------------------------------------------------------------------*/

.drive-browser-item {
    background-color: white;
    position: relative;
}

.drive-browser-item .square_content {
    padding: 0px;
}

.drive-browser-item-margin {
    position: relative;
    padding: 5px;
    width: 100%;
    height: 100%;
}
.drive-browser-item-content {
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.drive-browser-item.drive-browser-item-selected .drive-browser-item-content {
    background-color: #d8e7f6;
}

.drive-browser-item-thumbnail {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
}
.drive-browser-item-thumbnail img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.drive-browser-item-thumbnail svg {
    color: lightgray;
    position:absolute;
    height:70%;
    width: 100%;
    top:15%; right:0;
}

/*Icon+title reusable components*/

.drive-browser-item-icontitle {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 5px;
    display: flex;
    height: 34px;
}


.drive-browser-item-icontitle svg {
    padding: 3px;
    width: 23px;
    height: 23px;
    flex-grow: 0;
    flex-shrink: 0;
}

.drive-browser-item-icontitle .drive-browser-item-titletext {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
}

.drive-browser-item-icontitle .mv-circular-button {
    width: 23px;
    height: 23px;
    margin-left: 2px;
}

.drive-browser-item-icontitle .dropdown-item.disabled {
    opacity: 0.5;
}

/*Icon+title adjustments for larger version*/

.drive-browser-item-icontitle-large {
    height: 39px;
}
.drive-browser-item-icontitle-large svg {
    width: 29px;
    height: 29px;
}
.drive-browser-item-icontitle-large .drive-browser-item-titletext {
    font-size: 16px;
    padding-top: 4px;
}
.drive-browser-item-icontitle-large .mv-circular-button {
    width: 29px;
    height: 29px;
}


/*-----------------------------------------------------------------------------------
Drive Browser Grid
-----------------------------------------------------------------------------------*/

.drive-browser-grid .dbg-grid {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(160px,1fr));   
}

.drive-browser-scrollable-grid {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
}

/*-----------------------------------------------------------------------------------
Drive Browser Body
-----------------------------------------------------------------------------------*/

.drive-browser-body {
    position: absolute;
    height: 100%;
    width: 100%;
}

.drive-browser-body .dbb-main-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.drive-browser-body .file-drop-target {
    background-color: transparent!important;
}

/*
.drive-browser-info-dialog {

}
*/

/*-----------------------------------------------------------------------------------
Drive Browser Item Info Dialog
-----------------------------------------------------------------------------------*/

.drive-browser-info-dialog .modal-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.drive-browser-info-dialog .dbid-thumbnail-row {
    padding-top: 10px;
    padding-bottom: 10px;
}

.drive-browser-info-dialog .dbid-thumbnail-row img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.drive-browser-info-dialog .dbid-property-row {
    padding-top: 10px;
}
.drive-browser-info-dialog .dbid-property-name {
    min-width: 150px;
    flex-grow: 0;
    color: #525252;
}

.drive-browser-info-dialog .dbid-property-value {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: #212121;
}

/*-----------------------------------------------------------------------------------
Drive Browser Item Preview Dialog
-----------------------------------------------------------------------------------*/

/*these settings are mostly just here to override the bootstrap dialog to one that fills the screen
normally the big dark area comes from the modal-backdrop, but we fill the full screen with the dialog
and give it a dark background to get it all extra dark*/
.drive-browser-preview-dialog {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: unset;
    margin: unset;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ececec
}
.drive-browser-preview-dialog .modal-content {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 10px;
}
.drive-browser-preview-dialog .modal-header {
    width: 100%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
    padding: 0;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 10px;
    overflow: hidden;
}
.drive-browser-preview-dialog .modal-header>.mv-circular-button {
    flex-grow: 0;
    flex-shrink: 0;
    color: #ececec
}
.drive-browser-preview-dialog .modal-header>.drive-browser-item-icontitle{
    flex-shrink: 1;
    flex-grow: 1;
}
.drive-browser-preview-dialog .modal-body {
    width: 100%;
    background-color: transparent;
    padding: 0;
}

.drive-browser-preview-dialog .modal-body img {
    position: absolute;
    width: 100%;
    background-color: transparent;
    height: 100%;
    object-fit: contain;
    
}

.drive-browser-preview-dialog .video-js {
    position: absolute;
    width: 100%;
    background-color: transparent;
    height: 100%;    
}
