* {
    font-family: 'Montserrat', Arial, sans-serif;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

#root {
    position: relative
}

.navspace {
    height: 56px;
    background-color: #e9ecef;
}

.navspacehome {
    z-index: 1;
    width: 100%;
    position: absolute;
    opacity: 0.85;
}

.main-area {
    min-height: calc(100% - 88px);
}
main {
    max-width: 100%;
    position: relative;
}

#root>footer {
    height: 32px;
    padding: 4px;
}

html,
body {
    overflow-x: hidden;
}

.with-drop-shadow {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.4));
}

.dropdown-hide-arrow>.dropdown-toggle:after {
    display: none;
}

.usernavdropdown>.dropdown-toggle:after {
    display: none;
}

.usernavdropdown>.dropdown-toggle {
    padding: 0;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 5px;
}

.no-outline-button:focus {
    box-shadow: none !important
}

.low-padding-table>tbody>tr>td {
    padding-top: 1px;
}

.header {
    height: 56px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
}

.header>div {
    position: relative;
    height: 40px
}

.header img {
    height: 40px
}

.header .dropdown {
    display: inline-block;
    height: 40px;
    width: 40px;
}

.header .dropdown-toggle {
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 50%;
    border: none;
    box-shadow: none !important;
}

.header .dropdown-toggle:after {
    display: none;
}

.header-button {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 50%;
    border: none;
    box-shadow: none !important;
}

.header-left {
    margin-right: auto;
}

.header-left>* {
    margin-right: 8px;
}

.header-left>*:last-child {
    margin-right: 0;
}

.header-right {
    margin-right: 0px;
}

.header-right>* {
    margin-left: 8px;
}

.header-right>*:first-child {
    margin-left: 0;
}

.header-left .nav-item {
    margin-left: 10px
}

.sidebar .nav-item {
    margin-top: 15px;
}

.square {
    width: 100%;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.square_content {
    padding: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
}

.modal-open {
    padding-right: 0 !important;
}

.image-button {
    border: none !important;
    box-shadow: none !important;
    background-color: none !important;
}

.image-button:hover {
    box-shadow: none !important;
    background-color: lightgray !important;
}

.privacy-c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: italic
}

.privacy-c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.privacy-c3 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: "Arial";
    font-style: normal
}

.privacy-c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.privacy-c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
}

.privacy-c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.privacy-c7 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: right
}

.privacy-c5 {
    background-color: #ffffff;
    max-width: 451.4pt;
    padding: 72pt 72pt 72pt 72pt
}

.page-table {}

.page-table tbody {}

.page-table tr {
    cursor: "pointer";
    height: 47px
}

.page-table td {
    vertical-align: middle;
    padding-top: 4px;
    padding-bottom: 4px;
}

.page-table th {}

.page-table .icon-cell {
    padding: 4px;
    width: 40px
}

.page-table .icon-cell img {
    max-width: 100%
}

.page-table .info-cell {
    font-size: 80%;
}

.page-table .button-cell {
    width: 50px;
    max-width: 50px;
    padding: 0px;
}

.page-table .button-cell .dropdown {
    width: 35px;
    height: 35px;
    margin: auto;
}

.page-table .button-cell button {
    width: 100%;
    height: 100%;
    padding: 0px;
    border: none;
    box-shadow: none !important
}

.page-table .button-cell button,
.page-table .button-cell button:focus,
.page-table .button-cell button:active {
    background-color: lightgray!important;
}

.page-table .button-cell button:hover {
    background-color: darkgray!important;
}

.banner-row {
    background-color: #eef3f7;
    background-size: cover;
    background-position: center center;
    width: 100vw;
    height: 300px
}

.banner-row .row {
    height: 300px
}

.banner-row:nth-child(even) {
    color: white;
}

.banner-row:nth-child(odd) {
    color: black;
}

.banner-row-textbox {
    margin: auto;
    text-align: left;
}

.banner-row-imagebox {
    text-align: center;
    height: 300px;
}

.banner-row-imagebox>img {
    height: 250px;
    width: 250px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.launch-row {
    height: 50px;
    background-color: #414141;
}


/*for when used as card rather than a row in container*/

.launch-row.card {
    height: 55px
}

.launch-row .card-body {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 0px;
}

.launch-row .col {
    margin: auto;
    text-align: center
}

.launch-button {
    height: 44px
}

.launch-button img {
    height: 25px;
    margin-right: 10px;
    position: relative;
    top: -2px
}

.launch-button p {
    font-size: 1.2em;
    display: inline;
    position: relative;
    top: 1px
}

.launch-button:focus {
    box-shadow: none;
}

.top-banner {
    max-height: 50vw;
    position: relative
}

.top-banner img {
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.login-text {
    font-size: 1.6em;
    padding: 0;
    padding-left: 2px;
    padding-right: 2px;
    position: relative;
    left: 10px;
}

.member-box {
    font-size: 1.5rem
}

.member-box .member-image {
    width: 30px;
    height: 30px;
    margin-right: -15px;
    border-radius: 50%;
    border: rgb(206, 206, 206);
    border-width: 1px;
    border-style: solid;
    color: white;
    background-color: cadetblue;
}

.member-box button {
    width: 30px;
    height: 30px;
    padding: 1px;
    margin-left: 10px;
}

.member-box .member-image:last-child {
    margin-right: 5px;
}

.profile-menu>* {
    margin-bottom: 5px;
}

.profile-icon {
    padding: 0px;
    background: transparent;
    border: none;
    white-space: nowrap;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    color: black;
}

.profile-icon:focus,
.profile-icon:active {
    background: transparent !important;
    box-shadow: none !important;
}

.profile-icon:hover {
    background: #00000055 !important;
    box-shadow: none !important;
}

.profile-icon:active {
    background: #00000088 !important;
    box-shadow: none !important;
}

.header-right>.profile-icon {
    display: inline-block;
    width: unset;
    border-radius: 50%;
}

.icon-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.icon-image-overlay {
    height: 45px;
    width: 45px;
    position: absolute;
    left: -3px;
    top: -2px;
    border-radius: 50%;
}

.profile-icon .icon-image {
    border: none;
    border-radius: 0;
    color: #0b2b4e;
}

.profile-icon .icon-image-container {
    display: inline-block;
    border-radius: 50%;
    color: white;
    background-color: lightgray;
}

.profile-icon-username {
    display: inline-block;
    margin-left: 5px;
}

.header-button .icon-image {
    height: 30px;
    width: 30px;
}

.dropdown-toggle .icon-image {
    height: 30px;
    width: 30px;
}

.star-icon {
    position: absolute;
    color: red;
    right: 0;
    top: 0;
    height: 15px;
    width: 15px;
}

.local-video {
    max-width: 100%;
}

.local-video-controls {
    position: absolute;
    background: #e9ecefba;
    height: 56px;
    width: 100%;
    bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
}

.local-video-controls .dropdown {
    display: inline-block;
    height: 40px;
    width: 40px;
}

.local-video-controls .dropdown-toggle {
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 50%;
    border: none;
    box-shadow: none !important;
}

.local-video-controls .dropdown-toggle:after {
    display: none;
}

.stream-preview-iframe {
    position: absolute;
    left: 32.5%;
    top: 28.5%;
    width: 35%;
    height: 35%;
    border: none;
}

.device-item-selected {}

.stream-page-loading {
    display: none
}

.aspect-ratio-sizer {
    display: grid;
}

.aspect-ratio-sizer>* {
    grid-area: 1 / 1 / 2 / 2;
}

.upgrade-link {
    font-weight: bold;
    color: #007bff;
}

.modal-header,
.modal-footer {
    background-color: rgb(248 249 250);
}

.number {
    text-align: right;
}

.clickable {
    cursor: pointer;
}

.full-width {
    width: 100%
}

.evenly-spaced-children > *:not(:first-child) {
    margin-left: 5px;
}

.gap-above-children > * {
    margin-top: 5px;
}

.responsive-select {
    max-width: 100%;
    overflow-x: auto;
}

.disabled-row {
    opacity: 0.6;
}